import React from 'react';
import { ProjectsShow } from '../../components/Projects/ProjectsShow/ProjectsShow';
// import { InfoShow } from '../../components/Infos/InfoShow/InfoShow '
import styles from './PersonalSpace.module.scss';

export const PersonalSpace = () => {

	return (
		<div className={styles.container}>
			<ProjectsShow />
			{/* <InfoShow /> */}
		</div>
	);
}
